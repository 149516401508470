import { useEffect, useState } from 'react'
import { supabase } from '../../services/supabase'
import type { Session } from '@supabase/supabase-js'

export function useSession(): Session | null {
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setSession(session)
      })
      .catch(() => {
        console.error('Error getting session')
      })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return session
}
