import { supabase } from '../../services/supabase'

export function useSignOut(): void {
  ;(async () => {
    const { error } = await supabase.auth.signOut()

    if (error) {
      throw error
    }
  })().catch((error) => {
    if (error) {
      console.error(error)
    }
  })
}

export default useSignOut
