import React from 'react'
import styled, { css } from 'styled-components'

export type SkillLevelType = {
  level: number
  title: string
  skillLevel: null | number
}

const StyledDescription = styled.div<{
  level: number
  skillLevel: null | number
}>`
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.special};
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.colors.neutral[700]};
  padding-inline: ${({ theme }) => theme.spacing.factorOf(2)};

  ${({ skillLevel }) =>
    skillLevel !== null &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.background.level[skillLevel]};
    `}

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.factorOf(1)};
  }
`

const SkillLevel: React.FC<SkillLevelType> = ({ level, title, skillLevel }) => {
  return (
    <StyledDescription level={level} skillLevel={skillLevel}>
      {title}
    </StyledDescription>
  )
}

export default SkillLevel
