interface SkillLevel {
  title: string
}

const skillLevels: SkillLevel[] = [
  { title: `No Experience` },
  { title: `Little Experience` },
  { title: `Can Follow` },
  { title: `Can Lead` },
]

export default skillLevels
