import React from 'react'
import { useSignIn as signIn } from '../../hooks/auth/useSignIn'
import styled from 'styled-components'

const SkillsMatrixLogoContainer = styled.div`
  border-radius: 0 500px 0 0px;
`

const TopRightCurve = styled.div`
  border-radius: 0 0px 0 500px;
`

export default function SignIn(): JSX.Element {
  return (
    <div className="flex v-screen">
      <div className="flex flex-row flex-1">
        <div className="flex flex-1 bg-green-100 min-h-screen flex-col">
          <div className="flex flex-1 row">
            <div className="p-5 flex-1">
              <img src="/images/very-logo.png" width={113} className="m-8" />
            </div>

            <TopRightCurve className="bg-yellow-100 flex-1 mb-24" />
          </div>
          <div className="flex-1"></div>
          <div className="flex flex-1 mr-24 align-end">
            <SkillsMatrixLogoContainer className="flex flex-1 items-end pl-14 pb-24 bg-yellow-100">
              <div>
                <img
                  src="/images/skills-matrix-logo.png"
                  style={{
                    maxWidth: 400,
                  }}
                />
              </div>
            </SkillsMatrixLogoContainer>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-center content-center place-items-center bg-white min-h-screen items-center justify-items-center">
          <div>
            <div className="text-center">
              <h2>Hello Very!</h2>
              <p>Welcome to the Skills Matrix platform</p>
            </div>

            <div>
              <button
                className="m-auto p-6"
                onClick={signIn}
                aria-label="google sign in button"
                type="button"
              >
                <img src="/images/google-button.png" width={328} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
