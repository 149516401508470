import React from 'react'
import Select from 'react-select'
import { useTheme } from 'styled-components'
import useFetchActiveUser from '../../hooks/auth/useFetchActiveUser'
import useFetchUsers from '../../hooks/users/useFetchUsers'

type UsersSelectorType = {
  userProfileId: string | null
  handleChangeSelectedUser: (userProfileId: string) => void
}

const UserSelector: React.FC<UsersSelectorType> = ({
  userProfileId,
  handleChangeSelectedUser,
}) => {
  const { data: activeUserData, loading: loadingActiveUser } =
    useFetchActiveUser()
  const { data: usersData, loading: loadingUsersData } = useFetchUsers()

  const { colors, spacing } = useTheme()

  const removeEmailURLParam = () => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    params.delete('email')

    // Update the browser URL without the "email" parameter
    const newUrl = `${url.origin}${url.pathname}?${params.toString()}`
    window.history.replaceState(null, '', newUrl)
  }

  const handleChangeUserFilter = (id: string) => {
    handleChangeSelectedUser(id)
    removeEmailURLParam()
  }

  if (loadingActiveUser || loadingUsersData) {
    return <div>Loading...</div>
  }

  if (!activeUserData || !usersData) {
    return <></>
  }

  const options =
    usersData.map((user) => ({
      value: user.id || '',
      label: user.email || '',
    })) ?? []

  const getSelectedOption = (id: string | null) =>
    options.find((option) => option.value === id)

  return (
    <Select
      options={options}
      defaultValue={getSelectedOption(activeUserData.id)}
      value={getSelectedOption(userProfileId)}
      placeholder={'Select a user'}
      onChange={(user) => user && handleChangeUserFilter(user.value)}
      noOptionsMessage={() => 'No results found'}
      isSearchable
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: 320,
          marginBottom: 48,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          borderRadius: 4,
          borderColor: colors.neutral[300],
          ':hover': {
            borderColor: colors.neutral[500],
            cursor: 'pointer',
          },
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingInline: spacing.factorOf(3),
          paddingBlock: spacing.factorOf(2),
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: colors.neutral[500],
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          width: 0,
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: colors.neutral[500],
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          color: isSelected ? colors.brand.white : colors.neutral[500],
          backgroundColor: isSelected ? colors.neutral[500] : 'transparent',
          ':hover': {
            cursor: 'pointer',
          },
        }),
      }}
    />
  )
}

export default UserSelector
