import React from 'react'
import { TextButton } from '../ui/buttons/TextButton'
import { useSignOut } from '../../hooks/auth/useSignOut'
import {
  StyledHeader,
  StyledLink,
  StyledLogo,
  StyledProjectName,
} from './Header.styles'

export const Header = () => {
  return (
    <StyledHeader>
      <StyledLink href="/">
        <StyledLogo title="Logo" />
        <StyledProjectName> Skills Matrix </StyledProjectName>
      </StyledLink>

      <TextButton
        className="text-red-500 uppercase text-xs font-bold tracking-wide mr-0 ml-auto"
        onClick={useSignOut}
      >
        Logout
      </TextButton>
    </StyledHeader>
  )
}
