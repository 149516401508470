import React from 'react'

export interface BadgeProps extends React.ComponentPropsWithoutRef<'span'> {}

export const Badge = ({ children, className, ...props }: BadgeProps) => {
  return (
    <span
      className={`text-neutral-500 text-xs py-[2px] px-[10px] bg-neutral-200 border border-neutral-300 rounded-full ${
        className ? className : ''
      }`}
      {...props}
    >
      {children}
    </span>
  )
}
