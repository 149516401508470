import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.factorOf(9)};
`

export const StyledHeader = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.factorOf(8)};
  font-size: ${({ theme }) => theme.text.size['4xl']};
  font-weight: 600;
`
