import { supabase } from '../../services/supabase'

export async function upsertSkillForUser(
  interest: number | null,
  skillId: number,
  experience: number | null,
  userId: string
) {
  const { error } = await supabase.from('user_skills_experiences').upsert(
    [
      {
        interest_level: interest,
        skill_id: skillId,
        skill_level: experience,
        user_id: userId,
      },
    ],
    {
      onConflict: 'skill_id,user_id',
    }
  )
  return error
}
