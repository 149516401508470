import React from 'react'
import { BaseButton, BaseButtonProps } from './BaseButton'

export interface PrimaryButtonProps extends BaseButtonProps {}

export const PrimaryButton = ({
  children,
  className,
  ...rest
}: PrimaryButtonProps) => {
  return (
    <BaseButton
      {...rest}
      className={`border-0 text-xs bg-neutral-700 text-neutral-100 disabled:bg-neutral-400 tracking-wider
     ${className ? className : ''}`}
    >
      {children}
    </BaseButton>
  )
}
