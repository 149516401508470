interface InterestLevel {
  title: string
}

const interestLevels: InterestLevel[] = [
  { title: `Not Interested` },
  { title: `Meh` },
  { title: `Interested` },
  { title: `I love it` },
]

export default interestLevels
