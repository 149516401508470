import React, { useEffect } from 'react'
import styled from 'styled-components'
import { XCircleIcon } from '@heroicons/react/24/outline'

export type DialogType = {
  children: React.ReactNode
  handleClose: () => void
  dialogClassName?: string
}

const StyledBackdrop = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`

const StyledDialog = styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.spacing.factorOf(2)};
  margin: ${({ theme }) => theme.spacing.factorOf(8)};
  padding: ${({ theme }) => theme.spacing.factorOf(8)};
  position: relative;
`

const StyledCloseModalButton = styled.button`
  height: ${({ theme }) => theme.spacing.factorOf(6)};
  width: ${({ theme }) => theme.spacing.factorOf(6)};
`

const StyledDialogHeading = styled.div`
  display: flex;
  justify-content: end;
`

const Dialog: React.FC<DialogType> = ({
  children,
  handleClose,
  dialogClassName,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [])

  return (
    <StyledBackdrop>
      <StyledDialog className={dialogClassName} role="dialog">
        <StyledDialogHeading>
          <StyledCloseModalButton
            onClick={handleClose}
            aria-label="close dialog"
            type="button"
          >
            <XCircleIcon />
          </StyledCloseModalButton>
        </StyledDialogHeading>
        {children}
      </StyledDialog>
    </StyledBackdrop>
  )
}

export default Dialog
