import React from 'react'
import type {
  SkillWithTypes,
  UserSkillsExperiencesAlias,
} from '../../types/aliases'
import Skill from '../skill/Skill'
import {
  StyledSkillLayer,
  StyledSkillLayerTitle,
  StyledTitleBlock,
  StyledTitleText,
} from './SkillLayer.styles'

type SkillLayerType = {
  activeUserId: string
  skills: SkillWithTypes[]
  skillsExperiences: UserSkillsExperiencesAlias[]
  layerName: string
  userProfileId: string | null
}

const SkillLayer: React.FC<SkillLayerType> = ({
  activeUserId,
  skillsExperiences,
  skills,
  layerName,
  userProfileId,
}) => {
  return (
    <StyledSkillLayer>
      <StyledSkillLayerTitle>
        <StyledTitleText>{layerName}</StyledTitleText>
        <StyledTitleBlock />
      </StyledSkillLayerTitle>
      {skills.map((skillData: SkillWithTypes) => {
        const skillExp = skillsExperiences?.find(
          (expData: { skill_id: number }) => {
            return expData.skill_id === skillData.skill_id
          }
        )

        if (!skillData.skill_name || !skillData.skill_id) {
          throw new Error('Corrupted SkillData')
        }

        return (
          <Skill
            key={`${activeUserId}_${skillData.skill_id}`}
            activeUserId={activeUserId}
            description={skillData.skill_description ?? undefined}
            isOwnSkill={activeUserId === userProfileId}
            name={skillData.skill_name}
            skillId={skillData.skill_id}
            userExperienceLevel={skillExp?.skill_level ?? null}
            userInterestLevel={skillExp?.interest_level ?? null}
            tags={skillData.tag_names ?? []}
          />
        )
      })}
    </StyledSkillLayer>
  )
}

export default SkillLayer
