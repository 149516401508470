import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.neutral[700]};

    h1 {
      font-family: ${({ theme }) => theme.fonts.special};
      font-size: ${({ theme }) => theme.text.size['2xl']};
      font-weight: bold;
      color: ${({ theme }) => theme.colors.neutral[700]};
      margin: 0;
    }

    button {
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  }
`

export default Typography
