import { defaultSpacing } from './constants'

const colors = {
  neutral: {
    100: '#FFFFFF',
    200: '#F4F4F4',
    300: '#DBDBDB',
    400: '#A3A6A7',
    500: '#676D6E',
    600: '#323D3F',
    700: '#272F31',
  },
  brand: {
    white: '#FFFFFF',
    black: '#272F31',
  },
  text: {
    25: '#666e7a',
    50: '#58595b',
    75: '#333333',
    100: '#151619',
  },
  background: {
    white: '#fff',
    light: '#f2f2f2',
    medium: '#efeff0',
    dark: '#d8d8d9',
    skill: ['#FFFFFF', '#FFF9F7', '#FFF9E6', '#FAFFF1'],
    level: ['#A3A6A7', '#FF6D45', '#FFCD34', '#DAFF98'],
  },
  border: {
    light: '#ebf1f8',
    medium: '#d6d8dB',
    dark: '#d8d8d9',
    darkest: '#272F31',
  },
}

const text = {
  size: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '32px',
    '4xl': '48px',
    '5xl': '72px',
  },
}

const spacing = {
  factorOf: (factor: number): string => {
    return `${factor * defaultSpacing}px`
  },
  skill: {
    width: '150px',
    height: '120px',
  },
}

const breakpoints = {
  mobile: 375,
  tablet: 768,
}

const media = `@media (max-width: ${breakpoints.tablet}px)`

const fonts = {
  regular: 'PolySans, Helvetica, sans-serif',
  special: 'PolySans, Helvetica, sans-serif', // TODO: Add brand fonts
}

const customTheme = {
  colors,
  text,
  spacing,
  breakpoints,
  media,
  fonts,
}

export default customTheme
