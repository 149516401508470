import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/images/verylogo.svg'

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: ${({ theme }) => theme.spacing.factorOf(4)};
  padding-inline: ${({ theme }) => theme.spacing.factorOf(5)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[300]};
`

export const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledLogo = styled(Logo)`
  height: 25px;
  margin-right: ${({ theme }) => theme.spacing.factorOf(3)};
  padding-right: ${({ theme }) => theme.spacing.factorOf(3)};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral[700]};
`

export const StyledProjectName = styled.div`
  font-size: ${({ theme }) => theme.text.size.xs};
  letter-spacing: 1.5px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral[700]};
`
