import React from 'react'
import { OutlinedButton, OutlinedButtonProps } from './OutlinedButton'

interface ToggleableSkillButtonProps extends OutlinedButtonProps {
  isEnabled: boolean
  activeClass: string
}

export const ToggleableButton = ({
  isEnabled,
  children,
  activeClass,
  ...props
}: ToggleableSkillButtonProps) => {
  return (
    <OutlinedButton {...props} className={isEnabled ? activeClass : ''}>
      {children}
    </OutlinedButton>
  )
}
