import { useState, useEffect } from 'react'
import { PostgrestError } from '@supabase/supabase-js'
import { supabase } from '../../services/supabase'
import type { UserAlias } from '../../types/aliases'

function useFetchUsers() {
  const [users, setUsers] = useState<UserAlias[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [usersError, setUsersError] = useState<PostgrestError | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data, error } = await supabase.from('users').select()

      setUsers(data)
      setUsersError(error)
    })()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { data: users, loading, error: usersError }
}

export default useFetchUsers
