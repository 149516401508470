import { supabase } from '../../services/supabase'

// This is necessary for the OAuth handshake; this is where the user will be redirected after Supabase gets its auth token from Google
function getAppUrl(): string {
  const siteUrl = process.env.REACT_APP_SITE_URL

  if (!siteUrl) {
    throw new Error('Missing REACT_APP_SITE_URL')
  }

  return siteUrl
}

export function useSignIn(): void {
  ;(async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: getAppUrl(),
      },
    })
  })()
}
