import React, { useEffect, useState } from 'react'
import { SkillLayer } from '../../components'
import useFetchActiveUser from '../../hooks/auth/useFetchActiveUser'
import useFetchSkillExperiences from '../../hooks/user-skills-experiences/useFetchSkillExperiences'
import useFetchSkills from '../../hooks/user-skills-experiences/useFetchSkills'
import UserSelector from '../../components/user-selector/UserSelector'
import { supabase } from '../../services/supabase'
import { SkillAlias, SkillWithTypes, UserAlias } from '../../types/aliases'
import { StyledContainer, StyledHeader } from './SkillsPage.styles'
import { useSession } from '../../hooks/auth/useSession'

const SkillsPage: React.FC = () => {
  const { data: activeUserData, loading: loadingActiveUser } =
    useFetchActiveUser()
  const session = useSession()

  const {
    data: skillExperiencesData,
    loading: loadingSkillExperiences,
    setUserProfileId,
    userProfileId,
  } = useFetchSkillExperiences()
  const { data: rawSkillsData, loading: loadingSkillsData } = useFetchSkills()
  const [skillsData, setSkillsData] = useState<SkillWithTypes[]>([])

  useEffect(() => {
    const updatedSkillsData: SkillWithTypes[] = []

    if (rawSkillsData) {
      rawSkillsData.forEach((skill: SkillAlias) => {
        const matchingSkill = updatedSkillsData.filter((obj) => {
          return obj.skill_id === skill.skill_id
        })

        if (matchingSkill.length === 0) {
          const newSkill: SkillWithTypes = {
            skill_id: skill.skill_id,
            skill_description: skill.skill_description,
            skill_name: skill.skill_name,
            layer_name: skill.layer_name,
            tag_names: skill.tag_name ? [skill.tag_name] : [],
          }

          updatedSkillsData.push(newSkill)
        } else {
          matchingSkill[0].tag_names.push(skill.tag_name)
        }
      })

      setSkillsData(updatedSkillsData)
    }
  }, [rawSkillsData])

  const urlParams = new URLSearchParams(window.location.search)
  const paramsUserEmail = urlParams.get('email')

  useEffect(() => {
    const fetchUserData = async () => {
      if (paramsUserEmail) {
        return await supabase
          .from('users')
          .select()
          .filter('email', 'eq', paramsUserEmail)
      } else if (session?.user?.email) {
        return await supabase
          .from('users')
          .select()
          .filter('email', 'eq', session.user.email)
      } else {
        return Promise.reject()
      }
    }

    // Need to regenerate the TS types from Supabase, in the meantime, I'll verify this is actually a UserAlias here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isUserAlias(obj: any): obj is UserAlias {
      return 'id' in obj
    }

    fetchUserData()
      .then((response) => {
        const { data } = response

        if (data && isUserAlias(data[0])) {
          const userData = data[0]
          setUserProfileId(userData.id)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [paramsUserEmail, setUserProfileId, session?.user.email])

  const layers = {
    theory: skillsData?.filter((skill) => skill.layer_name === 'Theory') ?? [],
    physical:
      skillsData?.filter((skill) => skill.layer_name === 'Physical') ?? [],
    platform:
      skillsData?.filter((skill) => skill.layer_name === 'Platform/Runtime') ??
      [],
    language:
      skillsData?.filter((skill) => skill.layer_name === 'Language') ?? [],
    framework:
      skillsData?.filter((skill) => skill.layer_name === 'Framework') ?? [],
  }

  if (loadingSkillsData || loadingSkillExperiences || loadingActiveUser) {
    return <div>Loading...</div>
  } else if (
    activeUserData !== null &&
    !!activeUserData &&
    !!skillsData &&
    !!skillExperiencesData
  ) {
    return (
      <StyledContainer>
        <StyledHeader>Skills Matrix</StyledHeader>

        <main>
          <UserSelector
            userProfileId={userProfileId}
            handleChangeSelectedUser={(selectedUserId: string) => {
              setUserProfileId(selectedUserId)
            }}
          />

          <SkillLayer
            activeUserId={activeUserData.id}
            key="theory"
            layerName="Theory"
            skills={layers.theory}
            skillsExperiences={skillExperiencesData}
            userProfileId={userProfileId}
          />
          <SkillLayer
            activeUserId={activeUserData.id}
            key="physical"
            layerName="Physical"
            skills={layers.physical}
            skillsExperiences={skillExperiencesData}
            userProfileId={userProfileId}
          />
          <SkillLayer
            activeUserId={activeUserData.id}
            key="platform"
            layerName="Platform/Runtime"
            skills={layers.platform}
            skillsExperiences={skillExperiencesData}
            userProfileId={userProfileId}
          />
          <SkillLayer
            activeUserId={activeUserData.id}
            key="language"
            layerName="Language"
            skills={layers.language}
            skillsExperiences={skillExperiencesData}
            userProfileId={userProfileId}
          />
          <SkillLayer
            activeUserId={activeUserData.id}
            key="framework"
            layerName="Framework"
            skills={layers.framework}
            skillsExperiences={skillExperiencesData}
            userProfileId={userProfileId}
          />
        </main>
      </StyledContainer>
    )
  } else {
    return <div>Active User is NULL</div>
  }
}

export default SkillsPage
