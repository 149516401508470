import React from 'react'
import { BaseButton, BaseButtonProps } from './BaseButton'

export interface TextButtonProps extends BaseButtonProps {}

export const TextButton = ({
  children,
  className,
  ...rest
}: TextButtonProps) => {
  return (
    <BaseButton
      {...rest}
      className={`border-0 ring-0 text-neutral-700 text-xs tracking-wider ${
        className ? className : ''
      }`}
    >
      {children}
    </BaseButton>
  )
}
