import { createClient } from '@supabase/supabase-js'

// this is @cflinchbaugh's api key and URL for FE Guild OSI Matrix supabase app
/*
  The following two values come from the `supabase start` command output.
*/
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL

if (!SUPABASE_KEY) {
  throw new Error('Missing SUPABASE_KEY')
}

if (!SUPABASE_URL) {
  throw new Error('Missing SUPABASE_URL')
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY)
