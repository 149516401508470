import React from 'react'

export interface BaseButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {}

export const BaseButton = ({
  children,
  className,
  ...rest
}: BaseButtonProps) => {
  return (
    <button
      {...rest}
      className={`py-2 px-6 font-light min-w-[140px] min-h-[40px] ${
        className ? className : ''
      }`}
    >
      {children}
    </button>
  )
}
