import React, { ReactNode } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import Typography from './typography'
import theme from './custom-theme'

type Props = {
  children?: ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <Typography />

      {children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
