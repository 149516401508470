import React from 'react'
import interestLevels from '../../consts/interestLevels'
import { ToggleableButton } from '../ui/buttons/ToggleableButton'

export type SkillInterestSelectorType = {
  handleClickInterest: (level: number) => void
  interestLevel: number | null
  isDisabled?: boolean
}

const SkillInterestSelector: React.FC<SkillInterestSelectorType> = ({
  handleClickInterest,
  interestLevel,
  isDisabled = false,
}) => {
  return (
    <div>
      <h2 className="text-neutral-600 font-bold mb-4">
        Are you interested in this topic?
      </h2>
      <div
        className="flex gap-4 flex-wrap"
        role="listbox"
        aria-label="skill interest"
        aria-activedescendant={
          interestLevel !== null ? `skill-interest-${interestLevel}` : undefined
        }
      >
        {interestLevels.map((level, index) => (
          <ToggleableButton
            activeClass="bg-neutral-300 font-semibold"
            isEnabled={interestLevel === index}
            key={level.title}
            onClick={() => handleClickInterest(index)}
            role="option"
            id={`skill-interest-${interestLevel}`}
            aria-selected={interestLevel === index}
            disabled={isDisabled}
          >
            {level.title}
          </ToggleableButton>
        ))}
      </div>
    </div>
  )
}

export default SkillInterestSelector
