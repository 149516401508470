import { PostgrestError } from '@supabase/supabase-js'
import { useState, useEffect } from 'react'
import { supabase } from '../../services/supabase'
import type { SkillAlias } from '../../types/aliases'

type UseFetchSkillsType = {
  data: SkillAlias[] | null
  loading: boolean
  error: PostgrestError | null
}

function useFetchSkills(): UseFetchSkillsType {
  const [skillsData, setSkillsData] = useState<SkillAlias[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [skillsError, setSkillsError] = useState<PostgrestError | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data, error } = await supabase.from('skills_tags_view').select(`
          *
        `)

      setSkillsData(data)
      setSkillsError(error)
    })()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { data: skillsData, loading, error: skillsError }
}

export default useFetchSkills
