import styled from 'styled-components'

export const StyledSkillLayer = styled.section`
  display: grid;
  gap: ${({ theme }) => theme.spacing.factorOf(4)};
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ theme }) => theme.spacing.skill.width}, max-content)
  );
  margin-bottom: ${({ theme }) => theme.spacing.factorOf(12)};
`

export const StyledSkillLayerTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  grid-column: 1/-1;
  background-color: ${({ theme }) => theme.colors.neutral[200]};
  margin-bottom: ${({ theme }) => theme.spacing.factorOf(2)};
  padding-left: ${({ theme }) => theme.spacing.factorOf(8)};
`

export const StyledTitleText = styled.h3`
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-family: ${({ theme }) => theme.fonts.special};
  font-size: ${({ theme }) => theme.text.size.md};
`

export const StyledTitleBlock = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.neutral[700]};
`
