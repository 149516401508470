import { AuthError, User } from '@supabase/supabase-js'
import { useState, useEffect } from 'react'
import { supabase } from '../../services/supabase'

function useFetchActiveUser() {
  const [activeUserData, setActiveUserData] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [activeuserError, setActiveUserError] = useState<AuthError | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data, error } = await supabase.auth.getUser()

      if (data.user === null) {
        throw new Error('Active user is NULL')
      } else {
        setActiveUserData(data.user)
        setActiveUserError(error)
      }
    })()
      .catch((error: AuthError) => {
        setActiveUserError(error)
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { data: activeUserData, loading, error: activeuserError }
}

export default useFetchActiveUser
