import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import SignIn from './screens/sign-in/SignIn'
import SkillsPage from './screens/skills/SkillsPage'
import NavigationLayout from './components/navigation/NavigationLayout'
import { useSession } from './hooks/auth/useSession'

const App: React.FC = () => {
  const session = useSession()

  return (
    <Routes>
      {/* Unauthenticated Routes */}
      {!session && (
        <Route path="/">
          <Route index element={<SignIn />} />
          <Route path={'/sign-in'} element={<SignIn />} />
          <Route path="*" element={<div>Not Found</div>} />
        </Route>
      )}

      {/* Authenticated Routes */}
      {session && (
        <Route path={'/'} element={<NavigationLayout />}>
          <Route index element={<SkillsPage />} />{' '}
        </Route>
      )}
    </Routes>
  )
}

export default App
