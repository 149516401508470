import React from 'react'
import { BaseButton, BaseButtonProps } from './BaseButton'

export interface OutlinedButtonProps extends BaseButtonProps {}

export const OutlinedButton = ({
  children,
  className,
  ...rest
}: OutlinedButtonProps) => {
  return (
    <BaseButton
      {...rest}
      className={`text-neutral-700 border border-neutral-700 rounded ring-neutral-800 hover:ring-1 ring-opacity-50 disabled:pointer-events-none 
     ${className ? className : ''}`}
    >
      {children}
    </BaseButton>
  )
}
