import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../header/Header'

const NavigationLayout: React.FC = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default NavigationLayout
