import React, { useState } from 'react'
import { Tag } from './Skill'
import SkillExperienceSelector from './SkillExperienceSelector'
import SkillInterestSelector from './SkillInterestSelector'
import SkillDescription from './SkillDescription'
import { TextButton } from '../ui/buttons/TextButton'
import { PrimaryButton } from '../ui/buttons/PrimaryButton'

export type SkillDetailsType = {
  description?: string
  onConfirmation: (
    interestLevel: number | null,
    skillLevel: number | null
  ) => void
  onCancel: () => void
  interestLevel: number | null
  name: string
  skillLevel: number | null
  tags: Tag[]
  isOwnSkill: boolean
}

const SkillDetails: React.FC<SkillDetailsType> = ({
  description = '',
  interestLevel,
  name,
  skillLevel,
  tags,
  onConfirmation,
  onCancel,
  isOwnSkill,
}) => {
  const [newInterestLevel, setNewInterestLevel] = useState(interestLevel)
  const [newSkillLevel, setNewSkillLevel] = useState(skillLevel)

  return (
    <div className="flex flex-col gap-y-8">
      <SkillDescription title={name} description={description} tags={tags} />
      <SkillExperienceSelector
        handleClickExperience={(sl) => setNewSkillLevel(sl)}
        skillLevel={newSkillLevel}
        isDisabled={!isOwnSkill}
      />
      <SkillInterestSelector
        handleClickInterest={(il) => setNewInterestLevel(il)}
        interestLevel={newInterestLevel}
        isDisabled={!isOwnSkill}
      />
      <div className="flex justify-end gap-x-5">
        <TextButton className="uppercase" onClick={onCancel}>
          Cancel
        </TextButton>
        {isOwnSkill && (
          <PrimaryButton
            className="uppercase"
            onClick={() => onConfirmation(newInterestLevel, newSkillLevel)}
          >
            Confirm
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

export default SkillDetails
