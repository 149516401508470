import React, { useCallback, useEffect, useState } from 'react'
import interestLevels from '../../consts/interestLevels'
import skillLevels from '../../consts/skillLevels'
import Dialog from '../dialog/Dialog'
import SkillDetails from './SkillDetails'
import SkillLevel from './SkillLevel'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
import StyledSkillButton, {
  StyledInfoIcon,
  StyledLevelsContainer,
  StyledNameContainer,
  StyledSkillName,
} from './Skill.styles'
import { upsertSkillForUser } from '../../shared/api/Skill'

export type Tag = string
export type SkillType = {
  activeUserId: string
  description?: string
  name: string
  skillId: number
  tags?: Tag[]
  userExperienceLevel?: null | number
  userInterestLevel?: null | number
  isOwnSkill: boolean
}

const Skill: React.FC<SkillType> = ({
  activeUserId,
  description,
  name,
  skillId,
  tags = [],
  userExperienceLevel = null,
  userInterestLevel = null,
  isOwnSkill,
}) => {
  const [experienceLevel, setExperienceLevel] = useState(userExperienceLevel)
  const [interestLevel, setInterestLevel] = useState(userInterestLevel)
  const [showDetails, setShowDetails] = useState(false)

  useEffect(() => {
    setExperienceLevel(userExperienceLevel)
    setInterestLevel(userInterestLevel)
  }, [userExperienceLevel, userInterestLevel])

  const handleClick = (): void => {
    setShowDetails(true)
  }

  const postToSupabase = useCallback(
    async (experience: null | number, interest: null | number) => {
      const error = await upsertSkillForUser(
        interest,
        skillId,
        experience,
        activeUserId
      )

      if (error) {
        console.error(error)
      }
    },
    [activeUserId, skillId]
  )

  const onSkillChangeConfirmation = useCallback(
    (interestLevel: number | null, experienceLevel: number | null) => {
      setInterestLevel(interestLevel)
      setExperienceLevel(experienceLevel)
      setShowDetails(false)
      postToSupabase(experienceLevel, interestLevel).catch((error) => {
        console.error(error)
      })
    },
    [postToSupabase]
  )

  return (
    <>
      <StyledSkillButton
        onClick={handleClick}
        skillLevel={experienceLevel}
        interestLevel={interestLevel}
        aria-label={`${name} - ${
          experienceLevel !== null
            ? skillLevels[experienceLevel].title
            : 'No experience selected'
        }`}
      >
        <StyledNameContainer>
          <StyledSkillName skillLevel={experienceLevel}>{name}</StyledSkillName>
          <StyledInfoIcon>
            <InfoIcon />
          </StyledInfoIcon>
        </StyledNameContainer>
        <StyledLevelsContainer>
          {experienceLevel !== null && (
            <SkillLevel
              level={experienceLevel}
              title={skillLevels[experienceLevel].title}
              skillLevel={experienceLevel}
            />
          )}

          {interestLevel !== null && (
            <SkillLevel
              level={interestLevel}
              title={interestLevels[interestLevel].title}
              skillLevel={experienceLevel}
            />
          )}
        </StyledLevelsContainer>
      </StyledSkillButton>

      {showDetails && (
        <Dialog
          handleClose={() => setShowDetails(false)}
          dialogClassName="max-w-[720px]"
        >
          <SkillDetails
            description={description}
            interestLevel={interestLevel}
            name={name}
            onCancel={() => setShowDetails(false)}
            onConfirmation={onSkillChangeConfirmation}
            skillLevel={experienceLevel}
            tags={tags}
            isOwnSkill={isOwnSkill}
          />
        </Dialog>
      )}
    </>
  )
}

export default Skill
