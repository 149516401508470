import styled, { css } from 'styled-components'

const StyledSkillButton = styled.button<{
  interestLevel: null | number
  skillLevel: null | number
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.skill.width};
  height: ${({ theme }) => theme.spacing.skill.height};
  padding-inline: ${({ theme }) => theme.spacing.factorOf(3)};
  padding-block: ${({ theme }) => theme.spacing.factorOf(2)};
  border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
  border-radius: 4px;

  &:hover {
    transition: 0.25s;
    border: 1.5px solid ${({ theme }) => theme.colors.neutral[700]};
  }

  ${({ skillLevel }) =>
    skillLevel !== null &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.background.skill[skillLevel]};
      border: 1.5px solid ${({ theme }) => theme.colors.neutral[700]};
      justify-content: space-between;
    `}
`

export const StyledNameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledInfoIcon = styled.div`
  flex-shrink: 0;
`

export const StyledSkillName = styled.div<{ skillLevel: number | null }>`
  align-self: center;
  font-size: ${({ theme }) => theme.text.size.sm};
  font-weight: 600;
  text-align: left;
  margin-right: ${({ theme }) => theme.spacing.factorOf(1)};

  ${({ skillLevel }) =>
    skillLevel !== null &&
    css`
      align-self: flex-start;
    `}
`

export const StyledLevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default StyledSkillButton
