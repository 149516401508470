import { PostgrestError } from '@supabase/supabase-js'
import { useState, useEffect } from 'react'
import { supabase } from '../../services/supabase'
import type { UserSkillsExperiencesAlias } from '../../types/aliases'

type UseFetchSkillExperiencesType = {
  data:
    | {
        created_at: string | null
        id: number
        interest_level: number | null
        skill_id: number
        skill_level: number | null
        user_id: string
      }[]
    | null
  loading: boolean
  error: PostgrestError | null
  setUserProfileId: React.Dispatch<React.SetStateAction<string | null>>
  userProfileId: string | null
}

function useFetchSkillExperiences(): UseFetchSkillExperiencesType {
  const [skillsExpData, setSkillsExpData] = useState<
    UserSkillsExperiencesAlias[] | null
  >(null)
  const [loading, setLoading] = useState(true)
  const [skillsError, setSkillsError] = useState<PostgrestError | null>(null)
  const [userProfileId, setUserProfileId] = useState<string | null>(null)

  useEffect(() => {
    if (userProfileId) {
      ;(async () => {
        const { data, error } = await supabase
          .from('user_skills_experiences')
          .select('*')
          .filter('user_id', 'eq', userProfileId)
          .order('created_at', {
            ascending: false,
          })

        setSkillsExpData(data)
        setSkillsError(error)
      })()
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [userProfileId])

  return {
    data: skillsExpData,
    loading,
    error: skillsError,
    setUserProfileId,
    userProfileId,
  }
}

export default useFetchSkillExperiences
