import { Tag } from './Skill'
import React from 'react'
import { Badge } from '../ui/Badge'

export interface SkillDescriptionProps {
  title: string
  description?: string
  tags: Tag[]
}

const SkillDescription: React.FC<SkillDescriptionProps> = ({
  title,
  description,
  tags,
}) => {
  return (
    <div className="border-b border-neutral-300">
      <h1 className="text-neutral-700 text-xl mb-1">{title}</h1>
      <p className="text-neutral-500 font-light mb-5">{description}</p>
      <div className="flex flex-wrap gap-2 mb-8">
        {tags.map((tag, i) => (
          <Badge key={i}>{tag}</Badge>
        ))}
      </div>
    </div>
  )
}

export default SkillDescription
