import React from 'react'
import skillLevels from '../../consts/skillLevels'
import { ToggleableButton } from '../ui/buttons/ToggleableButton'

export type SkillExperienceSelectorType = {
  handleClickExperience: (level: number) => void
  skillLevel: number | null
  isDisabled?: boolean
}

const SkillExperienceSelector: React.FC<SkillExperienceSelectorType> = ({
  handleClickExperience,
  skillLevel,
  isDisabled = false,
}) => {
  return (
    <div>
      <h2 className="text-neutral-600 font-bold mb-4">
        How experienced are you?
      </h2>
      <div
        className="flex gap-4 flex-wrap"
        role="listbox"
        aria-label="skill experience"
        aria-activedescendant={
          skillLevel !== null ? `skill-level-${skillLevel}` : undefined
        }
      >
        {skillLevels.map((level, index) => (
          <ToggleableButton
            onClick={() => handleClickExperience(index)}
            isEnabled={skillLevel === index}
            key={level.title}
            type="button"
            activeClass="bg-yellow-500 font-semibold"
            role="option"
            id={`skill-level-${skillLevel}`}
            aria-selected={skillLevel === index}
            disabled={isDisabled}
          >
            {level.title}
          </ToggleableButton>
        ))}
      </div>
    </div>
  )
}

export default SkillExperienceSelector
